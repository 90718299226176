<script>
//import axios from "axios";

import Layout from "../../layouts/auth";
import {
    authMethods,
    adminMethods,
    //authFackMethods,
    notificationMethods,
} from "@/state/helpers";
//import { mapState } from "vuex";

import appConfig from "@/app.config";
import { required, email } from "vuelidate/lib/validators";

/**
 * Login component
 */
export default {
    page: {
        title: "Login",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
    },
    data() {
        return {
            email: "",
            password: "",
            submitted: false,
            authError: "",
            tryingToLogIn: false,
            isAuthError: false,
            passwordVisible: false, // Add state to handle password visibility
        };
    },
    validations: {
        email: {
            required,
            email,
        },
        password: {
            required,
        },
    },
    computed: {
        notification() {
            return this.$store ? this.$store.state.notification : null;
        },
    },
    methods: {
        ...authMethods,
        ...adminMethods,
        //...authFackMethods,
        ...notificationMethods,
        // Toggle password visibility
        togglePasswordVisibility() {
            this.passwordVisible = !this.passwordVisible;
        },
        // Try to log the user in with the username
        // and password they provided.
        tryToLogIn() {
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            } else {
                this.isLoading = true;
                this.tryingToLogIn = true;
                // Reset the authError if it existed.
                this.authError = null;
                return (
                    this.logIn({
                        email: this.email,
                        password: this.password,
                    })
                        // eslint-disable-next-line no-unused-vars
                        .then((response) => {
                            if (response.error != undefined) {
                                this.isLoading = false;
                                this.tryingToLogIn = false;
                                this.authError = response.error;
                                this.isAuthError = true;
                            } else {
                                this.tryingToLogIn = false;
                                this.isAuthError = false;
                                // Redirect to the originally requested page, or to the home page
                                this.loadCoreData().then(() => {
                                    // eslint-disable-next-line
                                    this.$router.push({
                                        path: this.$store.getters["admin/getValidUserRoute"],
                                    });
                                });
                            }
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            this.tryingToLogIn = false;
                            this.authError = error ? error : "";
                            this.isAuthError = true;
                        })
                );
            }
        },
    },
    mounted() {},
};
</script>

<template>
    <Layout>
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card overflow-hidden">
                    <div class="bg-soft-info">
                        <div class="row">
                            <div class="col-7">
                                <div class="text-primary p-4">
                                    <h5 class="text-primary">Welcome Back !</h5>
                                    <p>Sign in to continue</p>
                                </div>
                            </div>
                            <div class="col-5 align-self-end">
                                <img src="@/assets/images/client_logo_alpha.webp" alt class="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div>
                            <router-link tag="a" to="/">
                                <div style="text-align: center;">
                                    <span>
                                        <img src="@/assets/images/zol.webp" alt height="69" />
                                    </span>
                                </div>
                            </router-link>
                        </div>
                        <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>

                        <div v-if="notification.message" :class="'alert ' + notification.type">
                            {{ notification.message }}
                        </div>

                        <b-form class="p-2" @submit.prevent="tryToLogIn">
                            <b-form-group id="input-group-1" label="Email" label-for="input-1">
                                <b-form-input
                                    id="input-1"
                                    v-model="email"
                                    type="text"
                                    placeholder="Enter email"
                                    :class="{
                                        'is-invalid': submitted && $v.email.$error,
                                    }"
                                ></b-form-input>
                                <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                                    <span v-if="!$v.email.required">Email is required.</span>
                                    <span v-if="!$v.email.email">Please enter valid email.</span>
                                </div>
                            </b-form-group>

                            <b-form-group id="input-group-2" label="Password" label-for="input-2">
                                <div class="input-group">
                                    <b-form-input
                                        id="input-2"
                                        v-model="password"
                                        :type="passwordVisible ? 'text' : 'password'"
                                        placeholder="Enter password"
                                        :class="{
                                            'is-invalid': submitted && $v.password.$error,
                                        }"
                                    ></b-form-input>
                                    <div class="input-group-append">
                                        <b-button
                                            variant="outline-secondary"
                                            @click="togglePasswordVisibility"
                                        >
                                            <i :class="passwordVisible ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                                        </b-button>
                                    </div>
                                </div>
                                <div v-if="submitted && !$v.password.required" class="invalid-feedback">Password is required.</div>
                            </b-form-group>
                            <div class="custom-control custom-checkbox">
                                <input id="customControlInline" type="checkbox" class="custom-control-input" />
                                <label class="custom-control-label" for="customControlInline">Remember me</label>
                            </div>
                            <div class="mt-3">
                                <b-button type="submit" variant="primary" class="btn-block">Log In</b-button>
                            </div>
                            <div class="mt-4 text-center">
                                <!-- forgot password on the login page -->
                                <router-link tag="a" to="/forgot-password" class="text-muted">
                                    <i class="mdi mdi-lock me-1"></i> Forgot your password?
                                </router-link>
                            </div>
                        </b-form>
                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->

                <div class="mt-5 text-center">
                    <p>© {{ new Date().getFullYear() }} Dankfather</p>
                </div>
                <!-- end row -->
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </Layout>
</template>

<style lang="scss" module></style>
